@import '../../../node_modules/normalize.css/normalize.css';
@import './styles/typography.css';
@import './styles/common.css';

:global #root {
  display: flex;
  flex: 1;
  font-family: var(--font-sans-serif);
  font-weight: var(--font-weight-regular);
  color: #4a4a4a;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

textarea,
select,
input,
button {
  outline: none;
}

.scroll-bar {
  // for firefox
  scrollbar-width: thin;
  scrollbar-color: var(--chakra-colors-gray-400) transparent;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--chakra-colors-gray-400);
    border-radius: 2px;
    cursor: pointer;
  }
}
