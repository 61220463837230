.component {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
}

.component > input[type='radio'] {
  visibility: hidden;
}

.component:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 100%;
  border: 2px solid var(--text);
  background-color: var(--white);
}

.disabled:after {
  border-color: var(--gray);
}

.checked:after {
  background-color: var(--text);
  box-shadow: inset 0 0 0 3px var(--white);
}
