@import url(https://use.typekit.net/jqa1rvm.css);

:root {
  --font-sans-serif: 'neo-sans', sans-serif;
  --font-weight-regular: 300;
  --font-size-medium: 15px;
  --font-size-small: 10px;
  --font-size-semi: 12px;
  --font-size-large: 20px;
  --line-height-medium: 20px;
  --font-weight-heavy: 700;
  --line-height-small: 15px;
  --line-height-large: 24px;
}
