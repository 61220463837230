.icon {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}

.editIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.container {
  padding: 5px;
  width: 100%;
}

.bookingTitle {
  padding: 5px 0;
  font-size: 14px;
  width: 100%;
}

.bookingTitle > div {
  min-height: 14px;
}

.utils {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: solid 1px var(--darker-gray);
  text-decoration: none;
  color: var(--text);
  line-height: 30px;
  font-size: var(--font-size-medium);
  text-align: center;
  padding: 0 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.disabled {
  border: 1px solid #e6e6e6;
  color: #e6e6e6;
  cursor: default;
  user-select: none;
}

.reference {
  background-color: var(--lighter-gray);
  color: var(--darker-gray);
  font-size: var(--font-size-semi);
  min-height: 30px;
  display: flex;
  padding: 8px 10px;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 480px) {
  .utilsWrapper {
    justify-content: center;
  }

  .utils {
    flex-direction: row;
  }

  .utils > * {
    width: 32%;
  }

  .button {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .container {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 33.33%;
  }

  .utils {
    max-width: initial;
  }
}

.PS_RQ {
  background-color: var(--lighter-gray);
}

.PS_OK,
.PS_CB {
  background-color: var(--light-green);
}

.PS_CAN {
  background-color: var(--light-red);
}

.NSC {
  background-color: var(--light-yellow);
}
