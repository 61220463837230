@import '../../styles/colors.css';
@import '../../styles/typography.css';

.container {
  margin: 10px 10px 0;
}

.componentBodyWrapper {
  padding: 20px;
}

.headerRow {
  padding-bottom: 20px;
  border-bottom: 2px solid var(--gray);
}

.row {
  display: flex;
  flex-direction: column;
}

.row:last-child {
  padding-bottom: 20px;
}

.footerRow {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 20px;
  border-top: 2px solid var(--gray);
}

.commentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.box {
  border: 1px solid var(--gray);
  border-radius: 3px;
  min-width: 75px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-gray);
}

.line {
  border-bottom: 2px solid var(--gray);
  width: 100%;
  height: 2px;
  margin: 15px 0 20px;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding-right: 2.6px;
}

.addOrRemove {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__disabled {
    cursor: not-allowed;
  }
}

.addOrRemove:active svg {
  font-size: 36px;
  margin: -2px;
}

.addIcon {
  color: #ffda00;
  font-size: 32px;
}

.removeIcon {
  font-size: 32px;
}

.addRemoveColumn {
  display: flex;
  margin-top: 15px;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.next {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.cargoAlert {
  margin: 20px 0;
}

@media (min-width: 480px) {
  .row {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}

@media (min-width: 768px) {
  .footerRow {
    flex-direction: row;
  }

  .footerRow > * {
    margin: auto 0;
  }

  .commentWrapper {
    flex-direction: row;
  }

  .flexWrapper {
    flex-direction: row;
  }
}
