.wrapper {
  display: flex;
}

.decreaseIcon {
  font-size: 1.3em;
  cursor: pointer;
}

.increaseIcon {
  color: var(--green);
  font-size: 1.3em;
  cursor: pointer;
}

.value {
  margin: auto 5px;
}
