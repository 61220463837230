.component {
  padding: 10px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.component.top {
  align-items: flex-start;
}

.component.bottom {
  align-items: flex-end;
}

.component.right {
  justify-content: flex-end;
}

.component.left {
  justify-content: flex-start;
}

.component.border {
  border-bottom: 1px solid #f2f2f2;
}

.wrap {
  flex-wrap: wrap;
}

@media (min-width: 480px) {
  .component {
    flex-wrap: nowrap;
  }

  .component.wrap {
    flex-wrap: wrap;
  }
}
