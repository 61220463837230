.component {
  align-items: center;
  padding: 0 6px;
  justify-content: space-between;
  width: 100%;
}

.input {
  border: 1px solid var(--darker-gray);
  border-radius: 30px;
  position: relative;
  font-size: var(--font-size-medium);
  background: none;
  width: auto;
  z-index: 1;
  padding-left: 10px;
  min-height: 40px;
}

.icon {
  height: 21px;
  width: 21px;
  margin-left: 4px;
  cursor: pointer;
}

.iconWrapper {
  display: flex;
  height: 20px;
}

.options {
  padding: 0 0 1px 1px;
}

.advanced {
  margin-top: 10px;
}

.dateRange {
  margin-top: 10px;
}

.labelText {
  min-width: 30px;
  text-align: right;
}

@media (min-width: 768px) {
  .container {
    display: flex;
  }

  .advanced {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .dateRange {
    margin-top: 0;
  }
}

@media (min-width: 480px) {
  .dateRange {
    display: flex;
  }

  .labelText {
    min-width: auto;
  }
}
