.component {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-medium);
  font-style: var(--font-weight-regular);
  flex-wrap: nowrap;
  line-height: 40px;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.component:not(.vertical) > *:first-child {
  margin-right: 10px;
}

.vertical {
  display: block;
  line-height: 40px;
  margin-top: auto;
}

@media (min-width: 480px) {
  .component {
    width: auto;
    margin-top: auto;
    margin-right: 20px;
  }

  .component:last-child {
    margin-right: 0;
  }

  .slim {
    width: 15.5%;
  }

  .quarter {
    width: 25%;
  }

  .wide {
    width: 33%;
  }

  .full {
    width: 100%;
  }
}
