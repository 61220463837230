.component {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 15px 10px;
  display: flex;
  user-select: none;
}

.container {
  font-size: var(--font-size-medium);
  color: var(--text);
  display: flex;
  flex-direction: column;
  max-width: 1002px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.container > * {
  flex-grow: 1;
  display: flex;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-regular);
  line-height: 22px;
  padding: 5px;
}

.container > * > :first-child {
  margin-right: 10px;
  min-width: 24px;
  min-height: 24px;
}

.warning {
  background: var(--light-yellow);
}

.error {
  background: var(--light-yellow);
}

.info {
  background: var(--light-yellow);
}

.clickable {
  cursor: pointer;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .container > * {
    padding: 0 5px;
  }
}

.icon {
  position: absolute;
  right: 0;
  padding: 0;
}
