.component {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
  width: 100%;
  height: auto;
}

.isLoading {
  opacity: 0.8;
  pointer-events: none;
}

.controls {
  line-height: 40px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-large);
  border-top: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  user-select: none;
}

.arrow {
  line-height: 24px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.header {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-regular);
  background-color: var(--brand-blue);
  color: var(--white);
  text-transform: uppercase;
  line-height: 40px;
}

.days {
  display: flex;
  flex-wrap: wrap;
  color: var(--dark-gray);
  font-weight: var(--font-weight-regular);
  background-color: var(--baby-blue);
}

.day {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 14.2857142857%;
  cursor: pointer;
  font-size: var(--font-size-small);
}

/* calendar days */
.days .day {
  border-top: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  height: 40px;
}

.days .currentMonth {
  background-color: var(--white);
}

.days .current {
  box-shadow: inset 0 0 0 1px var(--brand-yellow);
  color: var(--text);
}

.icon {
  color: var(--brand-yellow);
}

.full {
  color: var(--green);
}

.empty {
  color: var(--gray);
}

.days .past {
  background-color: #ededed;
}

.day.limited {
  background: linear-gradient(
    -45deg,
    white 0%,
    white 10%,
    transparent 10%,
    transparent 20%,
    white 20%,
    white 30%,
    transparent 30%,
    transparent 40%,
    white 40%,
    white 50%,
    transparent 50%,
    transparent 60%,
    white 60%,
    white 70%,
    transparent 70%,
    transparent 80%,
    white 80%,
    white 90%,
    transparent 90%,
    transparent 100%
  );
}
