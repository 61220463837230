.componentBodyWrapper {
  padding: 10px;
}

.summary {
  border: 1px solid #e0e0e0;
}

.summaryHeader {
  padding: 16px 19px;
  border-bottom: 1px solid #e0e0e0;
}

.flexBetween {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.box {
  padding: 16px 19px;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.box:last-child {
  border-bottom: none;
}

.title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-heavy);
  line-height: 24px;
  text-align: left;
}

.line {
  border-bottom: 2px solid var(--gray);
  width: 100%;
  height: 2px;
  margin: 15px 0 20px;
}

.flexWrapper {
  width: 100%;
  display: flex;
  padding: 20px 0;
  flex-direction: column;
}

.alert {
  margin-bottom: 10px;
}

@media (min-width: 480px) {
  .flexWrapper {
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
    flex-direction: row;
  }

  .alert {
    width: 60%;
    margin-bottom: 0;
  }
}
