.container {
  width: 100%;
}

.component {
  background-color: var(--white);
  border: 1px solid var(--gray);
  width: 100%;
  margin: -1px 0;
}

.fill {
  flex: 1;
}

.content {
  overflow-y: hidden;
  height: auto;
  transition: height var(--transition);
}

.content.disabled {
  pointer-events: none;
  background-color: var(--lighter-gray);
  opacity: 0.7;
}

.header.disabled {
  opacity: 0.7;
  cursor: default;
  font-style: italic;
}

.header {
  background-color: var(--white);
  width: 100%;
  padding: 0 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  flex-wrap: wrap;
  cursor: pointer;
}

.header.open {
  background-color: var(--cyan);
  border-bottom: 1px solid var(--gray);
}

.header.canExpand {
  cursor: default;
}

.header.open.canExpand {
  background-color: var(--white);
}

.title {
  min-height: 50px;
  width: calc(100% - 24px);
  display: flex;
  align-items: center;
}

.additionalHeader {
  width: 100%;
  flex: 1;
}

.label {
  color: var(--text);
  font-size: var(--font-size-medium);
}

.route {
  color: var(--text);
  font-size: var(--font-size-medium);
  flex: 2;
  line-height: 50px;
}

.date {
  color: var(--text);
  font-size: var(--font-size-medium);
  flex: 1 0;
  width: 100%;
  white-space: nowrap;
}

.icon {
  font-size: 24px;
  color: var(--text);
  padding-right: 13px;
  width: 37px;
  height: 24px;
}

.arrow {
  font-size: 24px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
}

@media (min-width: 480px) {
  .header {
    flex-wrap: nowrap;
  }

  .container.full {
    width: 100%;
  }

  .title {
    order: 1;
  }

  .additionalHeader {
    order: 2;
  }

  .arrow {
    order: 3;
  }
}

@media (min-width: 768px) {
  .container {
    min-width: 33.33%;
  }
}
