.component {
  display: block;
}

@media (min-width: 480px) {
  .component {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;
    align-items: flex-start;
  }
}

.calendar {
  width: 100%;
}

.calendarInfo {
  padding: 5px 0 0 0;
  text-align: center;
  font-size: 12px;
  color: var(--darker-gray);
}

@media (min-width: 480px) {
  .calendar {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .calendar {
    width: 70%;
  }
}
