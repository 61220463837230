.container {
  margin: 10px 10px 0;
}

.header {
  display: flex;
  flex-direction: column;
}

.title {
  padding: 8px 0 14px 6px;
  color: var(--text);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-large);
}

.subtitle {
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  margin-top: 22px;
  margin-left: 16px;
}

.card {
  background-color: #fff;
  background-color: var(--white);
  border: 1px solid #e0e0e0;
  border: 1px solid var(--gray);
  width: 100%;
  margin: -1px 0;
  min-height: 120px;
  padding: 8px;
}

.tableWrapper {
  padding: 0 8px;
}

.departureTable {
  width: 100%;
  text-align: left;
  padding: 0 8px;
  border-collapse: collapse;
}

.tableHeader {
  height: 40px;
}

.tableHeader > th {
  font-weight: 400;
  padding: 8px;
}

.tableRow {
  height: 57px;
  border: 1px solid var(--gray);
}

.tableRow > td {
  padding: 8px;
}

.datetime {
  width: 100px;
}

.amount {
  text-align: center;
  width: 120px;
}

.amountInput {
  display: inline-block;
  width: 40px;
}

.changeAmount {
  display: flex;
  align-items: center;
}

.changeAmount :hover {
  cursor: pointer;
}

.changeAmount > svg {
  font-size: 20px;
  margin: 0 6px;
}

.status {
  text-align: right;
  width: 85px;
  line-height: 12px;
}

.status > svg {
  margin-right: 8px;
  cursor: pointer;
  font-size: 22px;
}

.success {
  color: green;
}

.failed {
  color: var(--red);
}

.loading {
  color: grey;
  animation: roll 4s linear infinite;
}

@keyframes roll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.line {
  border-bottom: 2px solid var(--gray);
  margin: 8px;
}

.row {
  padding: 8px;
}

.addRow {
  padding: 0px 16px 8px;
}

.addButton {
  flex: 2;
  text-align: right;
  margin-top: 24px;
}
