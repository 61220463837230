.button {
  background-color: var(--brand-blue);
  border-radius: 20px;
  height: 40px;
  cursor: pointer;
  padding: 0 40px;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  border: none;
}

.disabled {
  background-color: var(--darker-gray);
  cursor: default;
}

.label {
  display: flex; /* Button may contain additional content that will break it without flex */
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-family: var(--font-sans-serif);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-regular);
  cursor: pointer;
  margin: auto;
}

.inverted {
  background-color: var(--white);
  border: solid 2px var(--brand-blue);
}

.inverted .label {
  color: var(--brand-blue);
}

.grayScale {
  background-color: var(--white);
  border: solid 1px var(--dark-gray);
}

.grayScale .label {
  color: var(--text);
}

.withIcon {
  padding: 0 30px;
}

.withIcon .label > * {
  margin-left: 10px;
}

@media (min-width: 480px) {
  .button {
    width: auto;
    margin-bottom: inherit;
  }

  .button ~ .button {
    margin-left: 10px;
  }
}
