.backdrop {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.container {
  position: relative;
  display: flex;
  max-width: 480px;
  width: 100%;
  background: #fff;
  padding: 20px;
}

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.header {
  text-align: center;
  font-size: var(--font-size-large);
  padding-bottom: 20px;
}

.description {
  text-align: center;
  font-size: var(--font-size-medium);
  padding-bottom: 20px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}
