.componentBodyWrapper {
  padding: 20px;
}

.row {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 100%;
}

.line {
  border-bottom: 2px solid var(--gray);
  width: 100%;
  height: 2px;
  margin: 15px 0 20px;
}

.next {
  text-align: right;
}

.icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 4px;
  display: none;
}

.dash {
  color: var(--darker-gray);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-heavy);
  line-height: 40px;
  text-align: center;
  display: none;
}

@media (min-width: 480px) {
  .inputWrapper {
    display: flex;
    flex-direction: column;
    margin: 5px;
    width: auto;
  }

  .icon {
    display: block;
  }

  .dash {
    display: block;
  }
}
