:root {
  --black: #000;
  --white: #fff;
  --silver: #f2f2f2;
  --brand-blue: #0051a5;
  --brand-yellow: #ffda00;
  --text: #4a4a4a;
  --light-gray: #fafafa;
  --lighter-gray: #f3f3f3;
  --gray: #e0e0e0;
  --dark-gray: #9b9b9b;
  --darker-gray: #979797;
  --dark-mode-background: #121212;
  --dark-mode-surface: #1e1e1e;
  --dark-mode-brand-blue: #002a56;
  --dark-mode-on-surface: #E1E1E1;
  --inset-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  --green: #7ed321;
  --light-green: #cbffcd;
  --red: #bf2828;
  --light-red: #ffe6e5;
  --dark-cyan: #0051a5;
  --cyan: #aaddfa;
  --baby-blue: #edf9ff;
  --light-yellow: #fff7c5;
}
