.component {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: var(--white);
  border-radius: 3px;
  border: 2px solid var(--text);
}

.component > input[type='checkbox'] {
  visibility: hidden;
}

.disabled:after {
  border-color: var(--gray);
}

.checked {
  background-color: var(--text);
}

.checked:after {
  position: absolute;
  content: '';
  left: 4px;
  top: 0;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
