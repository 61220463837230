.container {
  margin: 10px 10px 0;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.title {
  color: var(--text);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-large);
}

.headerButtons {
  display: flex;
  flex-direction: column;
}

@media (min-width: 420px) {
  .header {
    justify-content: space-between;
    align-items: center;
  }

  .reservations {
    display: flex;
    flex-wrap: wrap;
  }

  .headerButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }

  .headerButtons > *:not(:first-child) {
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .header {
    flex-direction: row;
  }
}
