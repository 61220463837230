.select {
  overflow-y: hidden;
  height: 100%;
  border: solid 1px var(--gray);
  margin-top: 10px;
}

@media (min-width: 480px) {
  .select {
    width: 50%;
    margin-top: 0;
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .select {
    width: 40%;
  }
}

.selectHeader {
  line-height: 40px;
  font-size: var(--font-size-large);
  text-align: center;
}

.selectDate {
  line-height: 40px;
  font-size: var(--font-size-medium);
  text-align: center;
  background-color: var(--cyan);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.icon {
  width: 12px;
  height: 12px;
}

.item {
  display: flex;
  line-height: 30px;
  border-bottom: solid 1px var(--gray);
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-medium);
}

.time {
  width: 60%;
}

.empty > .time {
  color: var(--gray);
}

.empty > .downTimer {
  color: var(--gray);
}

.empty > .allotment {
  color: var(--gray);
}

.downTimer {
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allotment {
  min-width: 46px;
  text-align: right;
  padding: 0 10px;
  word-wrap: normal;
}

.selected {
  background-color: var(--lighter-gray);
}

.isLoading {
  opacity: 0.8;
  pointer-events: none;
}

.limited {
  background: linear-gradient(
    -45deg,
    transparent 0%,
    transparent 12.5%,
    var(--baby-blue) 12.5%,
    var(--baby-blue) 37.5%,
    transparent 37.5%,
    transparent 62.5%,
    var(--baby-blue) 62.5%,
    var(--baby-blue) 87.5%,
    transparent 87.5%,
    transparent 100%
  );
  background-size: 31px 31px;
}
