.container {
  width: 100%;
}

.component {
  border: 1px solid var(--gray);
  background-color: var(--white);
  border-radius: 3px;
  height: 40px;
  color: var(--text);
  font-family: var(--font-sans-serif);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-regular);
  line-height: 20px;
  text-align: left;
  padding: 9px 10px 11px;
  box-shadow: var(--inset-shadow);
  width: 100%;
}

::placeholder {
  color: #bbb;
}

.invalid {
  background-color: var(--light-red);
}

.readOnly {
  background-color: var(--light-gray);
  box-shadow: none;
}

.disabled {
  background-color: var(--light-gray);
  box-shadow: none;
}

.component[disabled] {
  background-color: var(--light-gray);
  color: var(--gray);
}

.suggest {
  position: absolute;
  background-color: var(--white);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
  z-index: 1000;
  top: 100%;
  left: 0;
  min-width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  max-height: 220px;
  overflow: auto;
}

.itemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;
}

.itemRowWrapper {
  position: relative;

  .itemRow:hover {
    background-color: var(--baby-blue);
  }
}

.item {
  padding: 5px 10px;
  flex: 2;
  cursor: pointer;
}

.hover {
  background-color: var(--baby-blue);
}

.deleteRow {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-50%);
}

.deleteRow:hover {
  color: red !important;
}
