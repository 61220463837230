.container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 420px) {
  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .container > *:not(:first-child) {
    margin-left: 10px;
  }
}
