.container {
  overflow: hidden;
  position: relative;
  display: inline;
}

.input {
  position: absolute;
  left: -999999px;
}

.button {
  all: revert;
  font-family: inherit;
  display: inline-block;
  font-size: 10px;
  cursor: pointer;
}
