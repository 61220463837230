.available {
  color: var(--brand-yellow);
}

.full {
  color: var(--green);
}

.empty {
  color: var(--gray);
}
