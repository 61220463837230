.component {
  display: flex;
  min-height: 100vh;
  flex: 1;
  font-family: var(--font-sans-serif);
  font-weight: var(--font-weight-regular);
}

.routes {
  display: flex;
  flex: 1;
  flex-direction: column;
}
