.departure {
  margin: 10px 0 0;
  padding-left: 20px;
}

.title {
  font-size: var(--font-size-medium);
  display: flex;
  height: 50px;
  align-items: center;
}

.titleLegend {
  display: none;
}

@media (min-width: 480px) {
  .titleHeader {
    min-width: 200px;
    margin: auto;
  }

  .titleLegend {
    margin-left: 42px;
    display: flex;
  }

  .titleLegendElement {
    min-width: 24px;
    height: 24px;
    border: 1px solid var(--gray);
    margin-left: 6px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    padding: 4px 3px 4px 3px;
    border-radius: 20px;
  }

  .waiting {
    background: var(--lighter-gray);
    margin-right: 6px;
  }

  .noshow {
    background: var(--light-yellow);
  }
}

.wrapRow {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.container {
  padding: 10px;
}

.wrapRow > * {
  padding: 0 5px;
}
