th {
  font-weight: normal;
}

.component {
  height: 100%;
  width: 50%;
  padding: 0.5vh;
  overflow: hidden;
}

.port {
  width: 100%;
  background-color: var(--white);
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}

.portTitle {
  padding: 5px 1vh;
  align-items: center;
  font-size: 4vh;
  letter-spacing: 3px;
  background: var(--brand-blue);
  color: var(--white);
}

thead svg {
  font-size: 3vh;
  color: var(--white);
}

.portDepartures {
  overflow: hidden;
}

.departureDate {
  margin-right: 10px;
  height: 100%;
  max-width: 7vw;
}

.departureTime {
  text-align: left;
}

.row.body {
  font-size: 3.95vh;
  font-weight: 500;
  padding: 0 1vh;
  border-bottom: 2px solid #c2c2c2;
}

.body.row:first-child {
  border-bottom: none;
}

.body.row:last-child {
  border-bottom: none;
}

.departureRow {
  height: 4.6vh; /*92vh divided for 20 lines*/
  align-items: center;
}

.today {
  background: var(--cyan);
}

.rowRight {
  align-items: center;
}

.available {
  font-weight: 500;
  align-items: flex-end;
  justify-content: center;
  margin-left: 1vw;
}

.protected {
  padding: 0 0.6vh;
  background: #e7e7e7;
  font-size: 3.3vh;
  border: 1px solid rgb(136, 136, 136);
}

.full {
  color: var(--red);
}

.free {
  color: var(--green);
}

.quantityHeader {
  font-size: 1.5vw;
}

.alignRight {
  text-align: right;
}

.departureRow.dark {
  background-color: var(--dark-mode-surface);
  color: var(--dark-mode-on-surface);
}
.protected.dark {
  background-color: var(--text);
  color:var(--dark-mode-on-surface);
  border: 1px solid var(--darker-gray);
}
.today.dark{
  background-color: var(--dark-cyan);
  color:var(--dark-mode-on-surface);
}
.portTitle.dark {
  background-color: var(--dark-mode-brand-blue);
  color: var(--dark-mode-on-surface);
}
.row.body.dark {
  border-bottom: 2px solid var(--text);
}
.port.dark {
  border: 1px solid var(--text);
}
